// [PURPOSE]: to define and create Angular Material Theme

// See https://material.angular.io/guide/theming

@use 'sass:map' as map;
@use 'sass:color' as color;
@use 'definitions';

@use '@angular/material' as mat;
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/material.css';
@import 'air-datepicker/air-datepicker.css';

// Edit default background colors
// todo maybe remove????  $mat-light-theme-background: map.merge(mat.$blue-palette, ());

// ========== COMPOSE LIGHT THEME ========== //
$primary: mat.m2-define-palette(definitions.$icz-primary-palette);
$accent: mat.m2-define-palette(mat.$m2-amber-palette, A200, A100, A400);
$warn: mat.m2-define-palette(mat.$m2-red-palette);
$theme: mat.m2-define-light-theme((color: (
  primary: $primary,
  accent: $accent,
  warn: $warn,
)));

// ========== CREATE THEME ========== //

@include mat.all-component-themes($theme);

// ========== CONFIG THEME ========== //

.module-config {
  icz-button {
    &.primary {
      --background-color: #{definitions.$brandpurple500};
      --hover-background-color: #{definitions.$brandpurple700};
    }
    &:not(.transparent) {
      --hover-background-color: #{definitions.$brandpurple200};
    }
  }

  .no-label-margin {
    &.no-label-margin {
      &.no-label-margin {
        .content {
          .label {
            color: definitions.$brandpurple500;
          }
        }
      }
    }
  }

  icz-spinner {
    .loader-default-color {
      border-bottom-color: definitions.$brandpurple700;
    }
  }

  .mat-primary {
    .mat-option {
      &.mat-selected:not(.mat-option-disabled) {
        &.mat-selected:not(.mat-option-disabled) {
          color: definitions.$brandpurple500;
        }
      }
    }
  }

  .save-filter-button {
    &.save-filter-button {
      background-color: definitions.$brandpurple500;
    }
  }

  icz-table {
    table tr.hoverable-row:not(.expandable-row):hover td, .hoverable-row:hover {
      background-color: rgba(definitions.$brandpurple300, 0.2) !important;
    }
    .active-row.active-row {
      background-color: definitions.$brandpurple300 !important;
    }
  }

  .mat-progress-spinner {
    &.mat-progress-spinner {
      circle {
        stroke: definitions.$brandpurple500;
      }
    }
  }

  .mat-spinner {
    &.mat-spinner {
      circle {
        stroke: definitions.$brandpurple500;
      }
    }
  }
}
