// [PURPOSE]: to define helper mixins and functions, which are used in other SCSS files
@use 'extends';
@use 'variables';
@use 'helpers';

// ========== RESPONSIVE COLUMN GRID ========== //

@mixin responsive-grid-columns($min-width, $type) {
  grid-template-columns: repeat(auto-#{$type}, minmax($min-width, 1fr));
}

// ========== GROUP OF ROUNDED ITEMS ========== //

@mixin compose-selector($prefix, $selector, $postfix) {
  #{$prefix}#{$selector}#{$postfix} {
    @content;
  }
}

@mixin first-group-item() {
  --top-right-radius: 0;
  --bottom-right-radius: 0;
  --border-right: none;
}

@mixin mid-group-item() {
  --top-left-radius: 0;
  --top-right-radius: 0;
  --bottom-left-radius: 0;
  --bottom-right-radius: 0;
  --border-right: none;
}

@mixin last-group-item() {
  --top-left-radius: 0;
  --bottom-left-radius: 0;
}

@mixin inaccessible-fade-in {
  animation-duration: 0.75s;
  animation-timing-function: linear;
}

@mixin horizontal-bar($min-height: variables.$horizontal-bar-height) {
  display: flex;
  align-items: center;
  padding: 0 helpers.spacing(12);
  min-height: $min-height;
  z-index: 1;
  > * {
    margin: auto 0.5rem;
  }
}

@mixin horizontal-bar-top($min-height: variables.$horizontal-bar-height) {
  @include horizontal-bar($min-height);
  box-shadow: inset 0 0 - variables.$split-width 0 0 variables.$split-color;
}

@mixin horizontal-bar-bottom {
  @include horizontal-bar;
  box-shadow: 0 0 - variables.$split-width 0 0 variables.$split-color;
}

@mixin scroll-vertically($thumb-background: variables.$white) {
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: $thumb-background;
  }
}

@mixin icz-card() {
  border: variables.$border-width solid variables.$grey500;
  background-color: variables.$white;
  box-shadow: 0 5px 5px 0 variables.$shadow-color;
}

@mixin host-with($class) {
  @at-root :host#{$class}#{$class} {
    @content;
  }
}

@mixin box-shadow($inset: null, $color: variables.$shadow-color) {
  box-shadow: $inset 2px 2px 10px 3px $color;
}

@mixin box-shadow-soft() {
  box-shadow: 1px 1px 4px variables.$shadow-color-soft;
}

@mixin focus-outline($color: variables.$azul70) {
  outline: 1px solid $color;
}

@mixin workspace($color: variables.$grey500, $shadow: false) {
  @extend %px-32, %pt-32, %pb-64, %col, %grow;
  background-color: $color;

  @if $shadow {
    @include box-shadow(inset);
  }
}

@mixin modal-spacing() {
  @include flex-container();
  padding: 0 helpers.spacing(24) helpers.spacing(24);
}

@mixin grow-no-overflow($grow: 1) {
  flex: $grow 1 0%;
  overflow: hidden;
}

@mixin flex-container($grow: 1) {
  @include grow-no-overflow($grow);
  display: flex;
}

@mixin singleline-text-ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
}

@mixin rounded-border($color: variables.$grey900, $radius: 0.15rem) {
  border: solid variables.$border-width $color;
  border-radius: $radius;
}

@mixin toolbar-buttons-border() {
  icz-button {
    --border-top: none;
    --border-bottom: none;
    --border-right: none;
    --border-left: none;
  }
}

@mixin chrome-focus-box-fix() {
  // The :focus-visible pseudo-class applies while an element matches the :focus pseudo-class
  // and the User Agent determines via heuristics that the focus should be made evident on the element.
  // :focus-visible is in Chrome since v83
  &:focus,
  &:focus-visible {
    outline: none;
  }
}
