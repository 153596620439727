// [PURPOSE]: to define GLOBAL CSS classes, which are reused in application
@use 'extends';
@use 'definitions';
@use 'animations';
@use 'sass:map' as map;

.tippy-box {
  $popover-shadow-color: rgba(45, 48, 57, 0.3);
  $popover-text-color: rgba(definitions.$white, 0.8);

  background-color: definitions.$black900;
  box-shadow: 0 4px 6px 0 $popover-shadow-color;

  .tippy-content {
    @extend %font-normal, %p-16, %whitespace-pre-wrap;
    color: $popover-text-color;
    font-size: 10pt;

    b {
      @extend %font-bold;
    }
  }
  .tippy-arrow {
    &::after {
      border-color: definitions.$black900;
    }
  }
}

.breakable-attribute {
  @extend %whitespace-pre-wrap;
  line-break: anywhere;
}

.icz-workspace {
  @include definitions.workspace();
  @include definitions.scroll-vertically();
}

.router-outlet {
  &-flex-container + * {
    @include definitions.flex-container();
  }
  &-flex-container-col + * {
    @include definitions.flex-container();
    @extend %flex-col;
  }
  &-block-container + * {
    @include definitions.flex-container();
    @extend %block;
  }
  &-grow-no-overflow + * {
    @include definitions.grow-no-overflow();
  }
  &-scroll-vertically + * {
    @include definitions.scroll-vertically();
  }
  &-workspace + * {
    @include definitions.workspace();
  }
}

.waiting {
  @extend %absolute, %h-full, %w-full;
  left: 0;
  top: 0;
  opacity: 0.7;
  z-index: 2;
}

.form-vertical-modulus {
  min-height: definitions.$form-vertical-modulus;
}

.mat-menu-panel {
  max-width: 350px !important;
}

.add-component-button {
  width: 250px;
}

.align-with-field-label {
  margin-top: 17px;
}

// ========== MODAL DIALOGS ==========

.icz-modal-dialog {
  .mat-dialog-container {
    @extend %flex, %overflow-hidden, %p-0;

    .icz-modal-title {
      font-family: 'Roboto', sans-serif;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      @extend %font-bold, %text-left;

      .label {
        font-size: 16px;
        line-height: 1.31;
        letter-spacing: normal;
      }

      .icz-modal-content-body {
        @extend %m-24;
        font-size: 14px;
        line-height: 1.71;
      }
    }
  }
  .cdk-drag {
    cursor: grab;
  }
}

// ========== OVERLAY ==========

.inaccessible-overlay {
  @include definitions.inaccessible-fade-in();
  animation-name: inaccessible-fade-in-normal;
  @extend %flex, %items-center, %justify-center, %absolute, %w-full, %h-full, %font-bolder;
  left: 0;
  top: 0;
  background: definitions.$white;
  text-shadow: 0 0 7px definitions.$white;
  opacity: 0.4;
  z-index: 2;
  color: definitions.$transparent;

  &:hover {
    color: definitions.$black900;
  }
}

.inaccessible-overlay-none {
  animation-name: inaccessible-fade-in-none;
  opacity: 0;
}

.search-input.search-input {
  --form-field-min-height: 40px;
  transition: width 0.2s ease-in;

  &#{&}#{&} {
    .validation-errors {
      @extend %pb-0;
    }
  }
}

.no-label-margin {
  &#{&}#{&} {
    &.blue {
      .content .label {
        color: definitions.$blue-link;
      }
    }
    &.green {
      $green-foreground: definitions.$brandgreen700;

      .content .label {
        color: $green-foreground;
      }
      mat-icon {
        & > svg {
          & > path {
            fill: $green-foreground !important;
          }
        }
      }
    }
    .content {
      @extend %w-40;

      .label {
        @extend %m-auto, %font-bolder;
      }
    }
  }
}

.row-button {
  min-height: 50px !important;
  text-decoration-color: definitions.$black900;
}

a {
  @extend %cursor-pointer;
  color: definitions.$blue-link;

  &[iczLinkWithoutHref] {
    text-decoration: underline;
  }
  &.icz-body-1 {
    text-decoration-color: definitions.$link-underline-color;
    color: definitions.$link-color;
  }
}

.no-options-found {
  @extend %p-8, %text-center;
  font-style: italic;
  color: definitions.$black700;
  font-size: map.get(definitions.$typography, input);
}

.visible-clickable-area {
  @extend %cursor-pointer;
  border-radius: definitions.spacing(4);
  transition: background-color 0.1s ease-in-out;

  &:hover {
    background-color: rgba(definitions.$black, 0.04); // 0.04 is from material button hover color
  }
}

.chip-list {
  @extend %flex, %flex-row, %items-center;
  flex-wrap: wrap;
}

.icz-body-strong, .mat-typography .icz-body-strong {
  font-size: 14px;
  font-weight: 500;
}

.mat-mdc-menu-panel icz-button {
  .mdc-button {
    @extend %justify-start;
  }

  .label {
    text-align: left;
  }
}

.list-loading, .list-loading-dark {
  height: 18px;
  animation-name: newspaper-loading;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

.list-loading-dark {
  animation-name: newspaper-loading-dark;
}

.table-view-selector {
  width: 200px;
}

icz-spinner {
  .loader {
    @extend %inline-block, %box-border;
    border: 8px solid transparent;

    border-radius: 50%;
    animation: rotation 2s linear infinite;
  }

  .loader-default-color {
    border-bottom-color: definitions.$brandgreen900;
  }

  .loader-inverted-color {
    border-bottom-color: definitions.$white;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
