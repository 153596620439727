@use 'variables';

// [PURPOSE]: to define CSS animations

@keyframes inaccessible-fade-in-normal {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.4;
  }
}

@keyframes inaccessible-fade-in-none {
  from {
    opacity: 0;
  }

  to {
    opacity: 0;
  }
}

@keyframes newspaper-loading {
  0% {
    background: variables.$grey500;
  }
  50% {
    background: variables.$grey700;
  }
  100% {
    background: variables.$grey500;
  }
}

@keyframes newspaper-loading-dark {
  0% {
    background: variables.$grey700;
  }
  50% {
    background: variables.$grey900;
  }
  100% {
    background: variables.$grey700;
  }
}
