@use 'extends';
// [PURPOSE]: to define GLOBAL basic styling for some element and classes
@use 'definitions';
@use 'sass:math';

h1,
h2,
h3,
h4 {
  font-weight: 400;
}

html,
body {
  height: 100%;
}

body {
  @include definitions.flex-container();
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: definitions.$brandcolor;
}

h1, .h1 { // taken from user agent stylesheets in chrome
  margin-top: 0.67em;
  margin-bottom: 0.67em;
}

h2, .h2 { // taken from user agent stylesheets in chrome
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

h3, .h3 { // taken from user agent stylesheets in chrome
  margin-top: 1em;
  margin-bottom: 1em;
}

hr {
  border: definitions.$split-border;
  border-left: none; // for vertical separator
  border-top: none; // for horizontal separator
  margin: 0 !important; // for both separator types
}

icz-icon {
  &.xlarge {
    .mat-icon {
      $size: 100px;
      height: $size;
      width: $size;
    }
  }
}

mark,
.highlight {
  background-color: definitions.$green500 !important;
}

icz-button {
  &.h-resize {
    cursor: ew-resize;
  }
}

.dialog-nopadding .modal-content.modal-content.modal-content {
  margin: 1px 0 0 0; // 1px because for 0px, the divider between modal title and modal content disappears
}

.acknowledged-notification-text {
  color: definitions.$black700;
}

.acknowledged-notification-icon {
  opacity: 0.7;
}

.text-danger {
  color: definitions.$red900;
}

.no-button-border {
  @include definitions.toolbar-buttons-border();
}

.ref-number {
  &#{&}#{&}#{&} {
    @extend %truncate, %whitespace-pre;
  }
}

.ref-number-multiline {
  &#{&}#{&}#{&} {
    @extend %whitespace-pre-wrap;
    line-break: anywhere;
  }
}

.body-text {
  color: definitions.$black900;
}

.card-divider {
  border-bottom: 1px solid definitions.$grey700;
}

@mixin form-field-focus-aura($isLastItem) {
  .input-wrapper.focused {
    @if $isLastItem == false {
      box-shadow: 1px 0 0 0 definitions.$azul70;

      &::after {
        content: '';
        position: absolute;
        top: -1px;
        right: -1px;
        height: calc(100% + 2px);
        width: 1px;
        background-color: definitions.$azul70;
        z-index: 1;
      }
    } @else {
      box-shadow: none;
    }
  }
}

@mixin groupable-button($selector) {
  & > #{$selector}:first-child {
    @include definitions.first-group-item();

    // mat-stroked-button is used for secondary buttons,
    // mat-flat-button for primary buttons.
    .mat-mdc-outlined-button.mat-mdc-outlined-button,
    .mat-mdc-unelevated-button.mat-mdc-unelevated-button {
      @include definitions.first-group-item();
    }
  }
  & > #{$selector}:not(:first-child):not(:last-child) {
    @include definitions.mid-group-item();

    .mat-mdc-outlined-button.mat-mdc-outlined-button,
    .mat-mdc-unelevated-button.mat-mdc-unelevated-button {
      @include definitions.mid-group-item();
    }
  }
  & > #{$selector}:last-child {
    @include definitions.last-group-item();

    .mat-mdc-outlined-button.mat-mdc-outlined-button,
    .mat-mdc-unelevated-button.mat-mdc-unelevated-button {
      @include definitions.last-group-item();
    }
  }
}

@mixin groupable-form-field($selector) {
  & > #{$selector}:first-child {
    @include definitions.first-group-item();
    @include form-field-focus-aura(false);

    icz-form-field {
      @include definitions.first-group-item();
    }
  }
  & > #{$selector}:not(:first-child):not(:last-child) {
    @include definitions.mid-group-item();
    @include form-field-focus-aura(false);

    icz-form-field {
      @include definitions.mid-group-item();
    }
  }
  & > #{$selector}:last-child {
    @include definitions.last-group-item();
    @include form-field-focus-aura(true);

    icz-form-field {
      @include definitions.last-group-item();
    }
  }
}

.row-group {
  @include groupable-button('icz-button');

  @include groupable-form-field('icz-form-field');
  @include groupable-form-field('icz-readonly-field');
  @include groupable-form-field('icz-form-autocomplete');
  @include groupable-form-field('icz-permission-selector');
  @include groupable-form-field('icz-org-structure-selector');
}
