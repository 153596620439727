// [PURPOSE]: to define all variables and maps, which are used in others SCSS files
@use 'sass:map' as map;
@use 'sass:color' as color;

// ========== APPLICATION PRIMARY THEME ========== //

// See http://mcg.mbitson.com/#!?iczblue=%233d80cc

$icz-primary-palette: (
  50: #e5edfb,
  100: #bfd2f5,
  200: #95b4ef,
  300: #6a95e8,
  400: #4a7fe3,
  500: #2a68de,
  600: #2560da,
  700: #1f55d5,
  800: #194bd1,
  900: #0f3ac8,
  A100: #f6f7ff,
  A200: #c3ceff,
  A400: #90a5ff,
  A700: #7690ff,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);

// ========== CUSTOM COLORS ========== //
$icz-form-field-background: #fff;

// ========== PREDEFINED COLOR PALETTE ========== //
$azul70: rgba(42, 104, 222, 0.75);
$black300: #d8d8d8;
$black500: #aaacaf;
$black600: #828282;
$black700: #6c6f75;
$black750: #3c404d;
$black800: #383b47;
$black900: #2d3039;
$blue300: #ecf4ff;
$blue400: #e3eaf5;
$blue500: #d4e0fc;
$blue500-transparent-01: rgba(61, 116, 245, 0.1);
$blue500-transparent-02: rgba(61, 116, 245, 0.2);
$blue900: #0f3ac8;
$blue-link: #004ef5;
$brandcolor: #103657;
$brandgreen500: #00c6b6;
$brandgreen700: #406475;
$brandgreen900: #0f3a56;
$brandpurple200: #e9e6fc;
$brandpurple300: #e2dff6;
$brandpurple500: #7d6dd9;
$brandpurple700: #6a61b8;
$brandpurple900: #313753;
$brandpurple1000: #323751;
$green500: #cde9ce;
$green700: #b5dcb6;
$green900: #4caf50;
$grey500: #f2f6f7;
$grey700: #e2e7eb;
$grey900: #d0d9de;
$grey1000: #b9c1c4;
$grey-background: #dadada;
$grey-deactivated: #c6d0d6;
$grey-text-disabled: #6d6d6d;
$orange400: #fff4e3;
$orange500: #ffe7c4;
$orange900: #ffac33;
$red500: #fad2cd;
$red900: #e82c17;
$red-error: #ca2715;
$white: #fff;
$black: #000;
$yellow500: #fcf8d7;

$button-primary-bg: #2a68de;
$button-primary-bg-hover: #2253b6;

$testing-env-color: $brandgreen500;
$training-env-color: $orange900;

$link-color: rgba(0, 0, 0, 0.77);
$link-underline-color: rgba(0, 0, 0, 0.35);

$primary-color: map.get($icz-primary-palette, 500);
$transparent: rgba(0, 0, 0, 0);

$shadow-color: rgba(0, 0, 0, 0.1);
$shadow-color-soft: rgba(0, 0, 0, 0.2);
$popover-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3);

$disabled-background-color: $grey500;
$disabled-border-color: $grey900;
$disabled-text: rgba(0, 0, 0, 0.38);

$invalid-bg: $red500;
$invalid-fg: $red900;
$required-bg: $yellow500;
$section-label: $black500;

$alert-text-color: #424242;

$typography: (
  alert: 16px,
  input: 14px,
  small: 12px,
);

$border-thickness: (
  thinnest: 0.1px,
  thin: 1px,
  thick: 2px,
);

$spacings: (
  0: 0px,
  1: 1px,
  2: 2px,
  4: 4px,
  8: 8px,
  12: 12px,
  16: 16px,
  24: 24px,
  32: 32px,
  40: 40px,
  48: 48px,
  64: 64px,
  128: 128px,
);

// ========== CUSTOM SIZINGS ========== //

$input-border-radius: 0.15rem;
$input-min-height: 34px;
$input-vertical-padding: 15px;
$input-vertical-offset: 2px;
$form-vertical-modulus: 52px;
$horizontal-bar-height: $form-vertical-modulus;
$tabs-height: $form-vertical-modulus;
$body-base-font-size: 14px;
$body-base-line-height: 20px;

$split-color: $grey700;
$split-width: 1px;
$split-border: solid $split-width $split-color;

$environment-line-width: 8px;

$table-footer-height: 52px;
$table-toolbar-item-height: 40px;
$table-th-height: 42px;
$table-th-font-size: 14px;
$table-tr-height: 36px;
$table-preview-width: 550px;
$table-preview-transition: 150ms;
$table-header-text-color: rgba(0, 0, 0, 0.54);

$section-label-font-size: 18px;

$button-min-size: 34px;

$border-width: map.get($border-thickness, thin);

$login-screen-logo-width: 250px;

$side-menu-width: 300px;
// because this application is gonna be non-responsive, there is a minimal
// screen width specified in which the app should not contain content
// area horizontal scrollbars.
$minimal-screen-width: 1280px;
// a reserve to allow for improperly maximized windows
$reserve: 10px;
// minimal width of the screen part near side menu if screen width
// is lower than this, a horizontal scrollbar will appear and
// scroll thru the whole contents of the screen
$minimal-content-width: $minimal-screen-width - $side-menu-width - $reserve - 1px; // = 939px

// height of bottom toolbars with buttons in modals
$form-buttons-height: 52px;

// width of calendar component
$calendar-width: 284px;
