/* stylelint-disable */
@use 'extends';
@use 'helpers';

/**
 * Utilities similar to Tailwind utilities but ready for Sass
 */

@layer utilities {
  .hidden {
    display: none !important;
  }
  .pointer-events-none {
    pointer-events: none !important;
  }
  .pointer-events-auto {
    pointer-events: auto !important;
  }
  .visible {
    visibility: visible !important;
  }
  .invisible {
    visibility: hidden !important;
  }
  .collapse {
    visibility: collapse !important;
  }
  .static {
    position: static !important;
  }
  .fixed {
    position: fixed !important;
  }
  .absolute {
    position: absolute !important;
  }
  .relative {
    position: relative !important;
  }
  .sticky {
    position: sticky !important;
  }
  .z--0 {
    z-index: 0 !important;
  }
  .z--10 {
    z-index: -10 !important;
  }
  .z--20 {
    z-index: -20 !important;
  }
  .z--30 {
    z-index: -30 !important;
  }
  .z--40 {
    z-index: -40 !important;
  }
  .z--50 {
    z-index: -50 !important;
  }
  .z-0 {
    z-index: 0 !important;
  }
  .z-10 {
    z-index: 10 !important;
  }
  .z-20 {
    z-index: 20 !important;
  }
  .z-30 {
    z-index: 30 !important;
  }
  .z-40 {
    z-index: 40 !important;
  }
  .z-50 {
    z-index: 50 !important;
  }
  .z-auto {
    z-index: auto !important;
  }
  .col-auto {
    grid-column: auto !important;
  }
  .col-span-1 {
    grid-column: span 1 / span 1 !important;
  }
  .col-span-10 {
    grid-column: span 10 / span 10 !important;
  }
  .col-span-11 {
    grid-column: span 11 / span 11 !important;
  }
  .col-span-12 {
    grid-column: span 12 / span 12 !important;
  }
  .col-span-2 {
    grid-column: span 2 / span 2 !important;
  }
  .col-span-3 {
    grid-column: span 3 / span 3 !important;
  }
  .col-span-4 {
    grid-column: span 4 / span 4 !important;
  }
  .col-span-5 {
    grid-column: span 5 / span 5 !important;
  }
  .col-span-6 {
    grid-column: span 6 / span 6 !important;
  }
  .col-span-7 {
    grid-column: span 7 / span 7 !important;
  }
  .col-span-8 {
    grid-column: span 8 / span 8 !important;
  }
  .col-span-9 {
    grid-column: span 9 / span 9 !important;
  }
  .col-span-full {
    grid-column: 1 / -1 !important;
  }
  .col-start-1 {
    grid-column-start: 1 !important;
  }
  .col-start-10 {
    grid-column-start: 10 !important;
  }
  .col-start-11 {
    grid-column-start: 11 !important;
  }
  .col-start-12 {
    grid-column-start: 12 !important;
  }
  .col-start-13 {
    grid-column-start: 13 !important;
  }
  .col-start-2 {
    grid-column-start: 2 !important;
  }
  .col-start-3 {
    grid-column-start: 3 !important;
  }
  .col-start-4 {
    grid-column-start: 4 !important;
  }
  .col-start-5 {
    grid-column-start: 5 !important;
  }
  .col-start-6 {
    grid-column-start: 6 !important;
  }
  .col-start-7 {
    grid-column-start: 7 !important;
  }
  .col-start-8 {
    grid-column-start: 8 !important;
  }
  .col-start-9 {
    grid-column-start: 9 !important;
  }
  .col-start-auto {
    grid-column-start: auto !important;
  }
  .col-end-1 {
    grid-column-end: 1 !important;
  }
  .col-end-10 {
    grid-column-end: 10 !important;
  }
  .col-end-11 {
    grid-column-end: 11 !important;
  }
  .col-end-12 {
    grid-column-end: 12 !important;
  }
  .col-end-13 {
    grid-column-end: 13 !important;
  }
  .col-end-2 {
    grid-column-end: 2 !important;
  }
  .col-end-3 {
    grid-column-end: 3 !important;
  }
  .col-end-4 {
    grid-column-end: 4 !important;
  }
  .col-end-5 {
    grid-column-end: 5 !important;
  }
  .col-end-6 {
    grid-column-end: 6 !important;
  }
  .col-end-7 {
    grid-column-end: 7 !important;
  }
  .col-end-8 {
    grid-column-end: 8 !important;
  }
  .col-end-9 {
    grid-column-end: 9 !important;
  }
  .col-end-auto {
    grid-column-end: auto !important;
  }
  .row-auto {
    grid-row: auto !important;
  }
  .row-span-1 {
    grid-row: span 1 / span 1 !important;
  }
  .row-span-10 {
    grid-row: span 10 / span 10 !important;
  }
  .row-span-11 {
    grid-row: span 11 / span 11 !important;
  }
  .row-span-12 {
    grid-row: span 12 / span 12 !important;
  }
  .row-span-2 {
    grid-row: span 2 / span 2 !important;
  }
  .row-span-3 {
    grid-row: span 3 / span 3 !important;
  }
  .row-span-4 {
    grid-row: span 4 / span 4 !important;
  }
  .row-span-5 {
    grid-row: span 5 / span 5 !important;
  }
  .row-span-6 {
    grid-row: span 6 / span 6 !important;
  }
  .row-span-7 {
    grid-row: span 7 / span 7 !important;
  }
  .row-span-8 {
    grid-row: span 8 / span 8 !important;
  }
  .row-span-9 {
    grid-row: span 9 / span 9 !important;
  }
  .row-span-full {
    grid-row: 1 / -1 !important;
  }
  .row-start-1 {
    grid-row-start: 1 !important;
  }
  .row-start-10 {
    grid-row-start: 10 !important;
  }
  .row-start-11 {
    grid-row-start: 11 !important;
  }
  .row-start-12 {
    grid-row-start: 12 !important;
  }
  .row-start-13 {
    grid-row-start: 13 !important;
  }
  .row-start-2 {
    grid-row-start: 2 !important;
  }
  .row-start-3 {
    grid-row-start: 3 !important;
  }
  .row-start-4 {
    grid-row-start: 4 !important;
  }
  .row-start-5 {
    grid-row-start: 5 !important;
  }
  .row-start-6 {
    grid-row-start: 6 !important;
  }
  .row-start-7 {
    grid-row-start: 7 !important;
  }
  .row-start-8 {
    grid-row-start: 8 !important;
  }
  .row-start-9 {
    grid-row-start: 9 !important;
  }
  .row-start-auto {
    grid-row-start: auto !important;
  }
  .row-end-1 {
    grid-row-end: 1 !important;
  }
  .row-end-10 {
    grid-row-end: 10 !important;
  }
  .row-end-11 {
    grid-row-end: 11 !important;
  }
  .row-end-12 {
    grid-row-end: 12 !important;
  }
  .row-end-13 {
    grid-row-end: 13 !important;
  }
  .row-end-2 {
    grid-row-end: 2 !important;
  }
  .row-end-3 {
    grid-row-end: 3 !important;
  }
  .row-end-4 {
    grid-row-end: 4 !important;
  }
  .row-end-5 {
    grid-row-end: 5 !important;
  }
  .row-end-6 {
    grid-row-end: 6 !important;
  }
  .row-end-7 {
    grid-row-end: 7 !important;
  }
  .row-end-8 {
    grid-row-end: 8 !important;
  }
  .row-end-9 {
    grid-row-end: 9 !important;
  }
  .row-end-auto {
    grid-row-end: auto !important;
  }
  .m--0 {
    margin: -1 * helpers.spacing(0) !important;
  }
  .m--1 {
    margin: -1 * helpers.spacing(1) !important;
  }
  .m--12 {
    margin: -1 * helpers.spacing(12) !important;
  }
  .m--128 {
    margin: -1 * helpers.spacing(128) !important;
  }
  .m--16 {
    margin: -1 * helpers.spacing(16) !important;
  }
  .m--2 {
    margin: -1 * helpers.spacing(2) !important;
  }
  .m--24 {
    margin: -1 * helpers.spacing(24) !important;
  }
  .m--32 {
    margin: -1 * helpers.spacing(32) !important;
  }
  .m--4 {
    margin: -1 * helpers.spacing(4) !important;
  }
  .m--40 {
    margin: -1 * helpers.spacing(40) !important;
  }
  .m--48 {
    margin: -1 * helpers.spacing(48) !important;
  }
  .m--64 {
    margin: -1 * helpers.spacing(64) !important;
  }
  .m--8 {
    margin: -1 * helpers.spacing(8) !important;
  }
  .m-0 {
    margin: helpers.spacing(0) !important;
  }
  .m-1 {
    margin: helpers.spacing(1) !important;
  }
  .m-12 {
    margin: helpers.spacing(12) !important;
  }
  .m-128 {
    margin: helpers.spacing(128) !important;
  }
  .m-16 {
    margin: helpers.spacing(16) !important;
  }
  .m-2 {
    margin: helpers.spacing(2) !important;
  }
  .m-24 {
    margin: helpers.spacing(24) !important;
  }
  .m-32 {
    margin: helpers.spacing(32) !important;
  }
  .m-4 {
    margin: helpers.spacing(4) !important;
  }
  .m-40 {
    margin: helpers.spacing(40) !important;
  }
  .m-48 {
    margin: helpers.spacing(48) !important;
  }
  .m-64 {
    margin: helpers.spacing(64) !important;
  }
  .m-8 {
    margin: helpers.spacing(8) !important;
  }
  .m-auto {
    margin: auto !important;
  }
  .mx--0 {
    margin-left: -1 * helpers.spacing(0) !important;
    margin-right: -1 * helpers.spacing(0) !important;
  }
  .mx--1 {
    margin-left: -1 * helpers.spacing(1) !important;
    margin-right: -1 * helpers.spacing(1) !important;
  }
  .mx--12 {
    margin-left: -1 * helpers.spacing(12) !important;
    margin-right: -1 * helpers.spacing(12) !important;
  }
  .mx--128 {
    margin-left: -1 * helpers.spacing(128) !important;
    margin-right: -1 * helpers.spacing(128) !important;
  }
  .mx--16 {
    margin-left: -1 * helpers.spacing(16) !important;
    margin-right: -1 * helpers.spacing(16) !important;
  }
  .mx--2 {
    margin-left: -1 * helpers.spacing(2) !important;
    margin-right: -1 * helpers.spacing(2) !important;
  }
  .mx--24 {
    margin-left: -1 * helpers.spacing(24) !important;
    margin-right: -1 * helpers.spacing(24) !important;
  }
  .mx--32 {
    margin-left: -1 * helpers.spacing(32) !important;
    margin-right: -1 * helpers.spacing(32) !important;
  }
  .mx--4 {
    margin-left: -1 * helpers.spacing(4) !important;
    margin-right: -1 * helpers.spacing(4) !important;
  }
  .mx--40 {
    margin-left: -1 * helpers.spacing(40) !important;
    margin-right: -1 * helpers.spacing(40) !important;
  }
  .mx--48 {
    margin-left: -1 * helpers.spacing(48) !important;
    margin-right: -1 * helpers.spacing(48) !important;
  }
  .mx--64 {
    margin-left: -1 * helpers.spacing(64) !important;
    margin-right: -1 * helpers.spacing(64) !important;
  }
  .mx--8 {
    margin-left: -1 * helpers.spacing(8) !important;
    margin-right: -1 * helpers.spacing(8) !important;
  }
  .my--0 {
    margin-top: -1 * helpers.spacing(0) !important;
    margin-bottom: -1 * helpers.spacing(0) !important;
  }
  .my--1 {
    margin-top: -1 * helpers.spacing(1) !important;
    margin-bottom: -1 * helpers.spacing(1) !important;
  }
  .my--12 {
    margin-top: -1 * helpers.spacing(12) !important;
    margin-bottom: -1 * helpers.spacing(12) !important;
  }
  .my--128 {
    margin-top: -1 * helpers.spacing(128) !important;
    margin-bottom: -1 * helpers.spacing(128) !important;
  }
  .my--16 {
    margin-top: -1 * helpers.spacing(16) !important;
    margin-bottom: -1 * helpers.spacing(16) !important;
  }
  .my--2 {
    margin-top: -1 * helpers.spacing(2) !important;
    margin-bottom: -1 * helpers.spacing(2) !important;
  }
  .my--24 {
    margin-top: -1 * helpers.spacing(24) !important;
    margin-bottom: -1 * helpers.spacing(24) !important;
  }
  .my--32 {
    margin-top: -1 * helpers.spacing(32) !important;
    margin-bottom: -1 * helpers.spacing(32) !important;
  }
  .my--4 {
    margin-top: -1 * helpers.spacing(4) !important;
    margin-bottom: -1 * helpers.spacing(4) !important;
  }
  .my--40 {
    margin-top: -1 * helpers.spacing(40) !important;
    margin-bottom: -1 * helpers.spacing(40) !important;
  }
  .my--48 {
    margin-top: -1 * helpers.spacing(48) !important;
    margin-bottom: -1 * helpers.spacing(48) !important;
  }
  .my--64 {
    margin-top: -1 * helpers.spacing(64) !important;
    margin-bottom: -1 * helpers.spacing(64) !important;
  }
  .my--8 {
    margin-top: -1 * helpers.spacing(8) !important;
    margin-bottom: -1 * helpers.spacing(8) !important;
  }
  .mx-0 {
    margin-left: helpers.spacing(0) !important;
    margin-right: helpers.spacing(0) !important;
  }
  .mx-1 {
    margin-left: helpers.spacing(1) !important;
    margin-right: helpers.spacing(1) !important;
  }
  .mx-12 {
    margin-left: helpers.spacing(12) !important;
    margin-right: helpers.spacing(12) !important;
  }
  .mx-128 {
    margin-left: helpers.spacing(128) !important;
    margin-right: helpers.spacing(128) !important;
  }
  .mx-16 {
    margin-left: helpers.spacing(16) !important;
    margin-right: helpers.spacing(16) !important;
  }
  .mx-2 {
    margin-left: helpers.spacing(2) !important;
    margin-right: helpers.spacing(2) !important;
  }
  .mx-24 {
    margin-left: helpers.spacing(24) !important;
    margin-right: helpers.spacing(24) !important;
  }
  .mx-32 {
    margin-left: helpers.spacing(32) !important;
    margin-right: helpers.spacing(32) !important;
  }
  .mx-4 {
    margin-left: helpers.spacing(4) !important;
    margin-right: helpers.spacing(4) !important;
  }
  .mx-40 {
    margin-left: helpers.spacing(40) !important;
    margin-right: helpers.spacing(40) !important;
  }
  .mx-48 {
    margin-left: helpers.spacing(48) !important;
    margin-right: helpers.spacing(48) !important;
  }
  .mx-64 {
    margin-left: helpers.spacing(64) !important;
    margin-right: helpers.spacing(64) !important;
  }
  .mx-8 {
    margin-left: helpers.spacing(8) !important;
    margin-right: helpers.spacing(8) !important;
  }
  .mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-0 {
    margin-top: helpers.spacing(0) !important;
    margin-bottom: helpers.spacing(0) !important;
  }
  .my-1 {
    margin-top: helpers.spacing(1) !important;
    margin-bottom: helpers.spacing(1) !important;
  }
  .my-12 {
    margin-top: helpers.spacing(12) !important;
    margin-bottom: helpers.spacing(12) !important;
  }
  .my-128 {
    margin-top: helpers.spacing(128) !important;
    margin-bottom: helpers.spacing(128) !important;
  }
  .my-16 {
    margin-top: helpers.spacing(16) !important;
    margin-bottom: helpers.spacing(16) !important;
  }
  .my-2 {
    margin-top: helpers.spacing(2) !important;
    margin-bottom: helpers.spacing(2) !important;
  }
  .my-24 {
    margin-top: helpers.spacing(24) !important;
    margin-bottom: helpers.spacing(24) !important;
  }
  .my-32 {
    margin-top: helpers.spacing(32) !important;
    margin-bottom: helpers.spacing(32) !important;
  }
  .my-4 {
    margin-top: helpers.spacing(4) !important;
    margin-bottom: helpers.spacing(4) !important;
  }
  .my-40 {
    margin-top: helpers.spacing(40) !important;
    margin-bottom: helpers.spacing(40) !important;
  }
  .my-48 {
    margin-top: helpers.spacing(48) !important;
    margin-bottom: helpers.spacing(48) !important;
  }
  .my-64 {
    margin-top: helpers.spacing(64) !important;
    margin-bottom: helpers.spacing(64) !important;
  }
  .my-8 {
    margin-top: helpers.spacing(8) !important;
    margin-bottom: helpers.spacing(8) !important;
  }
  .my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mb--0 {
    margin-bottom: -1 * helpers.spacing(0) !important;
  }
  .mb--1 {
    margin-bottom: -1 * helpers.spacing(1) !important;
  }
  .mb--12 {
    margin-bottom: -1 * helpers.spacing(12) !important;
  }
  .mb--128 {
    margin-bottom: -1 * helpers.spacing(128) !important;
  }
  .mb--16 {
    margin-bottom: -1 * helpers.spacing(16) !important;
  }
  .mb--2 {
    margin-bottom: -1 * helpers.spacing(2) !important;
  }
  .mb--24 {
    margin-bottom: -1 * helpers.spacing(24) !important;
  }
  .mb--32 {
    margin-bottom: -1 * helpers.spacing(32) !important;
  }
  .mb--4 {
    margin-bottom: -1 * helpers.spacing(4) !important;
  }
  .mb--40 {
    margin-bottom: -1 * helpers.spacing(40) !important;
  }
  .mb--48 {
    margin-bottom: -1 * helpers.spacing(48) !important;
  }
  .mb--64 {
    margin-bottom: -1 * helpers.spacing(64) !important;
  }
  .mb--8 {
    margin-bottom: -1 * helpers.spacing(8) !important;
  }
  .ml--0 {
    margin-left: -1 * helpers.spacing(0) !important;
  }
  .ml--1 {
    margin-left: -1 * helpers.spacing(1) !important;
  }
  .ml--12 {
    margin-left: -1 * helpers.spacing(12) !important;
  }
  .ml--128 {
    margin-left: -1 * helpers.spacing(128) !important;
  }
  .ml--16 {
    margin-left: -1 * helpers.spacing(16) !important;
  }
  .ml--2 {
    margin-left: -1 * helpers.spacing(2) !important;
  }
  .ml--24 {
    margin-left: -1 * helpers.spacing(24) !important;
  }
  .ml--32 {
    margin-left: -1 * helpers.spacing(32) !important;
  }
  .ml--4 {
    margin-left: -1 * helpers.spacing(4) !important;
  }
  .ml--40 {
    margin-left: -1 * helpers.spacing(40) !important;
  }
  .ml--48 {
    margin-left: -1 * helpers.spacing(48) !important;
  }
  .ml--64 {
    margin-left: -1 * helpers.spacing(64) !important;
  }
  .ml--8 {
    margin-left: -1 * helpers.spacing(8) !important;
  }
  .mr--0 {
    margin-right: -1 * helpers.spacing(0) !important;
  }
  .mr--1 {
    margin-right: -1 * helpers.spacing(1) !important;
  }
  .mr--12 {
    margin-right: -1 * helpers.spacing(12) !important;
  }
  .mr--128 {
    margin-right: -1 * helpers.spacing(128) !important;
  }
  .mr--16 {
    margin-right: -1 * helpers.spacing(16) !important;
  }
  .mr--2 {
    margin-right: -1 * helpers.spacing(2) !important;
  }
  .mr--24 {
    margin-right: -1 * helpers.spacing(24) !important;
  }
  .mr--32 {
    margin-right: -1 * helpers.spacing(32) !important;
  }
  .mr--4 {
    margin-right: -1 * helpers.spacing(4) !important;
  }
  .mr--40 {
    margin-right: -1 * helpers.spacing(40) !important;
  }
  .mr--48 {
    margin-right: -1 * helpers.spacing(48) !important;
  }
  .mr--64 {
    margin-right: -1 * helpers.spacing(64) !important;
  }
  .mr--8 {
    margin-right: -1 * helpers.spacing(8) !important;
  }
  .mt--0 {
    margin-top: -1 * helpers.spacing(0) !important;
  }
  .mt--1 {
    margin-top: -1 * helpers.spacing(1) !important;
  }
  .mt--12 {
    margin-top: -1 * helpers.spacing(12) !important;
  }
  .mt--128 {
    margin-top: -1 * helpers.spacing(128) !important;
  }
  .mt--16 {
    margin-top: -1 * helpers.spacing(16) !important;
  }
  .mt--2 {
    margin-top: -1 * helpers.spacing(2) !important;
  }
  .mt--24 {
    margin-top: -1 * helpers.spacing(24) !important;
  }
  .mt--32 {
    margin-top: -1 * helpers.spacing(32) !important;
  }
  .mt--4 {
    margin-top: -1 * helpers.spacing(4) !important;
  }
  .mt--40 {
    margin-top: -1 * helpers.spacing(40) !important;
  }
  .mt--48 {
    margin-top: -1 * helpers.spacing(48) !important;
  }
  .mt--64 {
    margin-top: -1 * helpers.spacing(64) !important;
  }
  .mt--8 {
    margin-top: -1 * helpers.spacing(8) !important;
  }
  .mb-0 {
    margin-bottom: helpers.spacing(0) !important;
  }
  .mb-1 {
    margin-bottom: helpers.spacing(1) !important;
  }
  .mb-12 {
    margin-bottom: helpers.spacing(12) !important;
  }
  .mb-128 {
    margin-bottom: helpers.spacing(128) !important;
  }
  .mb-16 {
    margin-bottom: helpers.spacing(16) !important;
  }
  .mb-2 {
    margin-bottom: helpers.spacing(2) !important;
  }
  .mb-24 {
    margin-bottom: helpers.spacing(24) !important;
  }
  .mb-32 {
    margin-bottom: helpers.spacing(32) !important;
  }
  .mb-4 {
    margin-bottom: helpers.spacing(4) !important;
  }
  .mb-40 {
    margin-bottom: helpers.spacing(40) !important;
  }
  .mb-48 {
    margin-bottom: helpers.spacing(48) !important;
  }
  .mb-64 {
    margin-bottom: helpers.spacing(64) !important;
  }
  .mb-8 {
    margin-bottom: helpers.spacing(8) !important;
  }
  .mb-auto {
    margin-bottom: auto !important;
  }
  .ml-0 {
    margin-left: helpers.spacing(0) !important;
  }
  .ml-1 {
    margin-left: helpers.spacing(1) !important;
  }
  .ml-12 {
    margin-left: helpers.spacing(12) !important;
  }
  .ml-128 {
    margin-left: helpers.spacing(128) !important;
  }
  .ml-16 {
    margin-left: helpers.spacing(16) !important;
  }
  .ml-2 {
    margin-left: helpers.spacing(2) !important;
  }
  .ml-24 {
    margin-left: helpers.spacing(24) !important;
  }
  .ml-32 {
    margin-left: helpers.spacing(32) !important;
  }
  .ml-4 {
    margin-left: helpers.spacing(4) !important;
  }
  .ml-40 {
    margin-left: helpers.spacing(40) !important;
  }
  .ml-48 {
    margin-left: helpers.spacing(48) !important;
  }
  .ml-64 {
    margin-left: helpers.spacing(64) !important;
  }
  .ml-8 {
    margin-left: helpers.spacing(8) !important;
  }
  .ml-auto {
    margin-left: auto !important;
  }
  .mr-0 {
    margin-right: helpers.spacing(0) !important;
  }
  .mr-1 {
    margin-right: helpers.spacing(1) !important;
  }
  .mr-12 {
    margin-right: helpers.spacing(12) !important;
  }
  .mr-128 {
    margin-right: helpers.spacing(128) !important;
  }
  .mr-16 {
    margin-right: helpers.spacing(16) !important;
  }
  .mr-2 {
    margin-right: helpers.spacing(2) !important;
  }
  .mr-24 {
    margin-right: helpers.spacing(24) !important;
  }
  .mr-32 {
    margin-right: helpers.spacing(32) !important;
  }
  .mr-4 {
    margin-right: helpers.spacing(4) !important;
  }
  .mr-40 {
    margin-right: helpers.spacing(40) !important;
  }
  .mr-48 {
    margin-right: helpers.spacing(48) !important;
  }
  .mr-64 {
    margin-right: helpers.spacing(64) !important;
  }
  .mr-8 {
    margin-right: helpers.spacing(8) !important;
  }
  .mr-auto {
    margin-right: auto !important;
  }
  .mt-0 {
    margin-top: helpers.spacing(0) !important;
  }
  .mt-1 {
    margin-top: helpers.spacing(1) !important;
  }
  .mt-12 {
    margin-top: helpers.spacing(12) !important;
  }
  .mt-128 {
    margin-top: helpers.spacing(128) !important;
  }
  .mt-16 {
    margin-top: helpers.spacing(16) !important;
  }
  .mt-2 {
    margin-top: helpers.spacing(2) !important;
  }
  .mt-24 {
    margin-top: helpers.spacing(24) !important;
  }
  .mt-32 {
    margin-top: helpers.spacing(32) !important;
  }
  .mt-4 {
    margin-top: helpers.spacing(4) !important;
  }
  .mt-40 {
    margin-top: helpers.spacing(40) !important;
  }
  .mt-48 {
    margin-top: helpers.spacing(48) !important;
  }
  .mt-64 {
    margin-top: helpers.spacing(64) !important;
  }
  .mt-8 {
    margin-top: helpers.spacing(8) !important;
  }
  .mt-auto {
    margin-top: auto !important;
  }
  .box-border {
    box-sizing: border-box !important;
  }
  .box-content {
    box-sizing: content-box !important;
  }
  .block {
    display: block !important;
  }
  .inline-block {
    display: inline-block !important;
  }
  .inline {
    display: inline !important;
  }
  .flex {
    display: flex !important;
  }
  .inline-flex {
    display: inline-flex !important;
  }
  .table {
    display: table !important;
  }
  .inline-table {
    display: inline-table !important;
  }
  .table-caption {
    display: table-caption !important;
  }
  .table-cell {
    display: table-cell !important;
  }
  .table-column {
    display: table-column !important;
  }
  .table-column-group {
    display: table-column-group !important;
  }
  .table-footer-group {
    display: table-footer-group !important;
  }
  .table-header-group {
    display: table-header-group !important;
  }
  .table-row-group {
    display: table-row-group !important;
  }
  .table-row {
    display: table-row !important;
  }
  .flow-root {
    display: flow-root !important;
  }
  .grid {
    display: grid !important;
  }
  .inline-grid {
    display: inline-grid !important;
  }
  .contents {
    display: contents !important;
  }
  .list-item {
    display: list-item !important;
  }
  .h-0 {
    height: helpers.spacing(0) !important;
  }
  .h-1 {
    height: helpers.spacing(1) !important;
  }
  .h-12 {
    height: helpers.spacing(12) !important;
  }
  .h-128 {
    height: helpers.spacing(128) !important;
  }
  .h-16 {
    height: helpers.spacing(16) !important;
  }
  .h-2 {
    height: helpers.spacing(2) !important;
  }
  .h-24 {
    height: helpers.spacing(24) !important;
  }
  .h-32 {
    height: helpers.spacing(32) !important;
  }
  .h-4 {
    height: helpers.spacing(4) !important;
  }
  .h-40 {
    height: helpers.spacing(40) !important;
  }
  .h-48 {
    height: helpers.spacing(48) !important;
  }
  .h-64 {
    height: helpers.spacing(64) !important;
  }
  .h-8 {
    height: helpers.spacing(8) !important;
  }
  .h-auto {
    height: auto !important;
  }
  .h-dvh {
    height: 100dvh !important;
  }
  .h-fit {
    height: fit-content !important;
  }
  .h-full {
    height: 100% !important;
  }
  .h-lvh {
    height: 100lvh !important;
  }
  .h-max {
    height: max-content !important;
  }
  .h-min {
    height: min-content !important;
  }
  .h-screen {
    height: 100vh !important;
  }
  .h-svh {
    height: 100svh !important;
  }
  .max-h-0 {
    max-height: helpers.spacing(0) !important;
  }
  .max-h-1 {
    max-height: helpers.spacing(1) !important;
  }
  .max-h-12 {
    max-height: helpers.spacing(12) !important;
  }
  .max-h-128 {
    max-height: helpers.spacing(128) !important;
  }
  .max-h-16 {
    max-height: helpers.spacing(16) !important;
  }
  .max-h-2 {
    max-height: helpers.spacing(2) !important;
  }
  .max-h-24 {
    max-height: helpers.spacing(24) !important;
  }
  .max-h-32 {
    max-height: helpers.spacing(32) !important;
  }
  .max-h-4 {
    max-height: helpers.spacing(4) !important;
  }
  .max-h-40 {
    max-height: helpers.spacing(40) !important;
  }
  .max-h-48 {
    max-height: helpers.spacing(48) !important;
  }
  .max-h-64 {
    max-height: helpers.spacing(64) !important;
  }
  .max-h-8 {
    max-height: helpers.spacing(8) !important;
  }
  .max-h-dvh {
    max-height: 100dvh !important;
  }
  .max-h-fit {
    max-height: fit-content !important;
  }
  .max-h-full {
    max-height: 100% !important;
  }
  .max-h-lvh {
    max-height: 100lvh !important;
  }
  .max-h-max {
    max-height: max-content !important;
  }
  .max-h-min {
    max-height: min-content !important;
  }
  .max-h-none {
    max-height: none !important;
  }
  .max-h-screen {
    max-height: 100vh !important;
  }
  .max-h-svh {
    max-height: 100svh !important;
  }
  .min-h-0 {
    min-height: helpers.spacing(0) !important;
  }
  .min-h-1 {
    min-height: helpers.spacing(1) !important;
  }
  .min-h-12 {
    min-height: helpers.spacing(12) !important;
  }
  .min-h-128 {
    min-height: helpers.spacing(128) !important;
  }
  .min-h-16 {
    min-height: helpers.spacing(16) !important;
  }
  .min-h-2 {
    min-height: helpers.spacing(2) !important;
  }
  .min-h-24 {
    min-height: helpers.spacing(24) !important;
  }
  .min-h-32 {
    min-height: helpers.spacing(32) !important;
  }
  .min-h-4 {
    min-height: helpers.spacing(4) !important;
  }
  .min-h-40 {
    min-height: helpers.spacing(40) !important;
  }
  .min-h-48 {
    min-height: helpers.spacing(48) !important;
  }
  .min-h-64 {
    min-height: helpers.spacing(64) !important;
  }
  .min-h-8 {
    min-height: helpers.spacing(8) !important;
  }
  .min-h-dvh {
    min-height: 100dvh !important;
  }
  .min-h-fit {
    min-height: fit-content !important;
  }
  .min-h-full {
    min-height: 100% !important;
  }
  .min-h-lvh {
    min-height: 100lvh !important;
  }
  .min-h-max {
    min-height: max-content !important;
  }
  .min-h-min {
    min-height: min-content !important;
  }
  .min-h-screen {
    min-height: 100vh !important;
  }
  .min-h-svh {
    min-height: 100svh !important;
  }
  .w-0 {
    width: helpers.spacing(0) !important;
  }
  .w-1 {
    width: helpers.spacing(1) !important;
  }
  .w-12 {
    width: helpers.spacing(12) !important;
  }
  .w-128 {
    width: helpers.spacing(128) !important;
  }
  .w-16 {
    width: helpers.spacing(16) !important;
  }
  .w-2 {
    width: helpers.spacing(2) !important;
  }
  .w-24 {
    width: helpers.spacing(24) !important;
  }
  .w-32 {
    width: helpers.spacing(32) !important;
  }
  .w-4 {
    width: helpers.spacing(4) !important;
  }
  .w-40 {
    width: helpers.spacing(40) !important;
  }
  .w-48 {
    width: helpers.spacing(48) !important;
  }
  .w-64 {
    width: helpers.spacing(64) !important;
  }
  .w-8 {
    width: helpers.spacing(8) !important;
  }
  .w-auto {
    width: auto !important;
  }
  .w-dvw {
    width: 100dvw !important;
  }
  .w-fit {
    width: fit-content !important;
  }
  .w-full {
    width: 100% !important;
  }
  .w-lvw {
    width: 100lvw !important;
  }
  .w-max {
    width: max-content !important;
  }
  .w-min {
    width: min-content !important;
  }
  .w-screen {
    width: 100vw !important;
  }
  .w-svw {
    width: 100svw !important;
  }
  .min-w-0 {
    min-width: helpers.spacing(0) !important;
  }
  .min-w-1 {
    min-width: helpers.spacing(1) !important;
  }
  .min-w-12 {
    min-width: helpers.spacing(12) !important;
  }
  .min-w-128 {
    min-width: helpers.spacing(128) !important;
  }
  .min-w-16 {
    min-width: helpers.spacing(16) !important;
  }
  .min-w-2 {
    min-width: helpers.spacing(2) !important;
  }
  .min-w-24 {
    min-width: helpers.spacing(24) !important;
  }
  .min-w-32 {
    min-width: helpers.spacing(32) !important;
  }
  .min-w-4 {
    min-width: helpers.spacing(4) !important;
  }
  .min-w-40 {
    min-width: helpers.spacing(40) !important;
  }
  .min-w-48 {
    min-width: helpers.spacing(48) !important;
  }
  .min-w-64 {
    min-width: helpers.spacing(64) !important;
  }
  .min-w-8 {
    min-width: helpers.spacing(8) !important;
  }
  .min-w-fit {
    min-width: fit-content !important;
  }
  .min-w-full {
    min-width: 100% !important;
  }
  .min-w-max {
    min-width: max-content !important;
  }
  .min-w-min {
    min-width: min-content !important;
  }
  .max-w-0 {
    max-width: helpers.spacing(0) !important;
  }
  .max-w-1 {
    max-width: helpers.spacing(1) !important;
  }
  .max-w-12 {
    max-width: helpers.spacing(12) !important;
  }
  .max-w-128 {
    max-width: helpers.spacing(128) !important;
  }
  .max-w-16 {
    max-width: helpers.spacing(16) !important;
  }
  .max-w-2 {
    max-width: helpers.spacing(2) !important;
  }
  .max-w-24 {
    max-width: helpers.spacing(24) !important;
  }
  .max-w-32 {
    max-width: helpers.spacing(32) !important;
  }
  .max-w-4 {
    max-width: helpers.spacing(4) !important;
  }
  .max-w-40 {
    max-width: helpers.spacing(40) !important;
  }
  .max-w-48 {
    max-width: helpers.spacing(48) !important;
  }
  .max-w-64 {
    max-width: helpers.spacing(64) !important;
  }
  .max-w-8 {
    max-width: helpers.spacing(8) !important;
  }
  .max-w-fit {
    max-width: fit-content !important;
  }
  .max-w-full {
    max-width: 100% !important;
  }
  .max-w-max {
    max-width: max-content !important;
  }
  .max-w-min {
    max-width: min-content !important;
  }
  .max-w-none {
    max-width: none !important;
  }
  .flex-1 {
    flex: 1 1 0% !important;
  }
  .flex-auto {
    flex: 1 1 auto !important;
  }
  .flex-initial {
    flex: 0 1 auto !important;
  }
  .flex-none {
    flex: none !important;
  }
  .flex-grow {
    flex-grow: 1 !important;
  }
  .flex-grow-0 {
    flex-grow: 0 !important;
  }
  .grow-0 {
    flex-grow: 0 !important;
  }
  .cursor-alias {
    cursor: alias !important;
  }
  .cursor-all-scroll {
    cursor: all-scroll !important;
  }
  .cursor-auto {
    cursor: auto !important;
  }
  .cursor-cell {
    cursor: cell !important;
  }
  .cursor-col-resize {
    cursor: col-resize !important;
  }
  .cursor-context-menu {
    cursor: context-menu !important;
  }
  .cursor-copy {
    cursor: copy !important;
  }
  .cursor-crosshair {
    cursor: crosshair !important;
  }
  .cursor-default {
    cursor: default !important;
  }
  .cursor-e-resize {
    cursor: e-resize !important;
  }
  .cursor-ew-resize {
    cursor: ew-resize !important;
  }
  .cursor-grab {
    cursor: grab !important;
  }
  .cursor-grabbing {
    cursor: grabbing !important;
  }
  .cursor-help {
    cursor: help !important;
  }
  .cursor-move {
    cursor: move !important;
  }
  .cursor-n-resize {
    cursor: n-resize !important;
  }
  .cursor-ne-resize {
    cursor: ne-resize !important;
  }
  .cursor-nesw-resize {
    cursor: nesw-resize !important;
  }
  .cursor-no-drop {
    cursor: no-drop !important;
  }
  .cursor-none {
    cursor: none !important;
  }
  .cursor-not-allowed {
    cursor: not-allowed !important;
  }
  .cursor-ns-resize {
    cursor: ns-resize !important;
  }
  .cursor-nw-resize {
    cursor: nw-resize !important;
  }
  .cursor-nwse-resize {
    cursor: nwse-resize !important;
  }
  .cursor-pointer {
    cursor: pointer !important;
  }
  .cursor-progress {
    cursor: progress !important;
  }
  .cursor-row-resize {
    cursor: row-resize !important;
  }
  .cursor-s-resize {
    cursor: s-resize !important;
  }
  .cursor-se-resize {
    cursor: se-resize !important;
  }
  .cursor-sw-resize {
    cursor: sw-resize !important;
  }
  .cursor-text {
    cursor: text !important;
  }
  .cursor-vertical-text {
    cursor: vertical-text !important;
  }
  .cursor-w-resize {
    cursor: w-resize !important;
  }
  .cursor-wait {
    cursor: wait !important;
  }
  .cursor-zoom-in {
    cursor: zoom-in !important;
  }
  .cursor-zoom-out {
    cursor: zoom-out !important;
  }
  .auto-cols-auto {
    grid-auto-columns: auto !important;
  }
  .auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr) !important;
  }
  .auto-cols-max {
    grid-auto-columns: max-content !important;
  }
  .auto-cols-min {
    grid-auto-columns: min-content !important;
  }
  .grid-flow-row {
    grid-auto-flow: row !important;
  }
  .grid-flow-col {
    grid-auto-flow: column !important;
  }
  .grid-flow-dense {
    grid-auto-flow: dense !important;
  }
  .grid-flow-row-dense {
    grid-auto-flow: row dense !important;
  }
  .grid-flow-col-dense {
    grid-auto-flow: column dense !important;
  }
  .auto-rows-auto {
    grid-auto-rows: auto !important;
  }
  .auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr) !important;
  }
  .auto-rows-max {
    grid-auto-rows: max-content !important;
  }
  .auto-rows-min {
    grid-auto-rows: min-content !important;
  }
  .grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
  .grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
  }
  .grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
  }
  .grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }
  .grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
  .grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }
  .grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
  .grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }
  .grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }
  .grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  }
  .grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }
  .grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
  }
  .grid-cols-none {
    grid-template-columns: none !important;
  }
  .grid-cols-subgrid {
    grid-template-columns: subgrid !important;
  }
  .grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }
  .grid-rows-10 {
    grid-template-rows: repeat(10, minmax(0, 1fr)) !important;
  }
  .grid-rows-11 {
    grid-template-rows: repeat(11, minmax(0, 1fr)) !important;
  }
  .grid-rows-12 {
    grid-template-rows: repeat(12, minmax(0, 1fr)) !important;
  }
  .grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
  }
  .grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
  }
  .grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
  }
  .grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
  }
  .grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
  }
  .grid-rows-7 {
    grid-template-rows: repeat(7, minmax(0, 1fr)) !important;
  }
  .grid-rows-8 {
    grid-template-rows: repeat(8, minmax(0, 1fr)) !important;
  }
  .grid-rows-9 {
    grid-template-rows: repeat(9, minmax(0, 1fr)) !important;
  }
  .grid-rows-none {
    grid-template-rows: none !important;
  }
  .grid-rows-subgrid {
    grid-template-rows: subgrid !important;
  }
  .flex-row {
    flex-direction: row !important;
  }
  .flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-col {
    flex-direction: column !important;
  }
  .flex-col-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-wrap {
    flex-wrap: wrap !important;
  }
  .flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-nowrap {
    flex-wrap: nowrap !important;
  }
  .place-content-center {
    place-content: center !important;
  }
  .place-content-start {
    place-content: start !important;
  }
  .place-content-end {
    place-content: end !important;
  }
  .place-content-between {
    place-content: space-between !important;
  }
  .place-content-around {
    place-content: space-around !important;
  }
  .place-content-evenly {
    place-content: space-evenly !important;
  }
  .place-content-baseline {
    place-content: baseline !important;
  }
  .place-content-stretch {
    place-content: stretch !important;
  }
  .items-start {
    align-items: flex-start !important;
  }
  .items-end {
    align-items: flex-end !important;
  }
  .items-center {
    align-items: center !important;
  }
  .items-baseline {
    align-items: baseline !important;
  }
  .items-stretch {
    align-items: stretch !important;
  }
  .justify-normal {
    justify-content: normal !important;
  }
  .justify-start {
    justify-content: flex-start !important;
  }
  .justify-end {
    justify-content: flex-end !important;
  }
  .justify-center {
    justify-content: center !important;
  }
  .justify-between {
    justify-content: space-between !important;
  }
  .justify-around {
    justify-content: space-around !important;
  }
  .justify-evenly {
    justify-content: space-evenly !important;
  }
  .justify-stretch {
    justify-content: stretch !important;
  }
  .gap-0 {
    gap: helpers.spacing(0) !important;
  }
  .gap-1 {
    gap: helpers.spacing(1) !important;
  }
  .gap-12 {
    gap: helpers.spacing(12) !important;
  }
  .gap-128 {
    gap: helpers.spacing(128) !important;
  }
  .gap-16 {
    gap: helpers.spacing(16) !important;
  }
  .gap-2 {
    gap: helpers.spacing(2) !important;
  }
  .gap-24 {
    gap: helpers.spacing(24) !important;
  }
  .gap-32 {
    gap: helpers.spacing(32) !important;
  }
  .gap-4 {
    gap: helpers.spacing(4) !important;
  }
  .gap-40 {
    gap: helpers.spacing(40) !important;
  }
  .gap-48 {
    gap: helpers.spacing(48) !important;
  }
  .gap-64 {
    gap: helpers.spacing(64) !important;
  }
  .gap-8 {
    gap: helpers.spacing(8) !important;
  }
  .gap-x-0 {
    column-gap: helpers.spacing(0) !important;
  }
  .gap-x-1 {
    column-gap: helpers.spacing(1) !important;
  }
  .gap-x-12 {
    column-gap: helpers.spacing(12) !important;
  }
  .gap-x-128 {
    column-gap: helpers.spacing(128) !important;
  }
  .gap-x-16 {
    column-gap: helpers.spacing(16) !important;
  }
  .gap-x-2 {
    column-gap: helpers.spacing(2) !important;
  }
  .gap-x-24 {
    column-gap: helpers.spacing(24) !important;
  }
  .gap-x-32 {
    column-gap: helpers.spacing(32) !important;
  }
  .gap-x-4 {
    column-gap: helpers.spacing(4) !important;
  }
  .gap-x-40 {
    column-gap: helpers.spacing(40) !important;
  }
  .gap-x-48 {
    column-gap: helpers.spacing(48) !important;
  }
  .gap-x-64 {
    column-gap: helpers.spacing(64) !important;
  }
  .gap-x-8 {
    column-gap: helpers.spacing(8) !important;
  }
  .gap-y-0 {
    row-gap: helpers.spacing(0) !important;
  }
  .gap-y-1 {
    row-gap: helpers.spacing(1) !important;
  }
  .gap-y-12 {
    row-gap: helpers.spacing(12) !important;
  }
  .gap-y-128 {
    row-gap: helpers.spacing(128) !important;
  }
  .gap-y-16 {
    row-gap: helpers.spacing(16) !important;
  }
  .gap-y-2 {
    row-gap: helpers.spacing(2) !important;
  }
  .gap-y-24 {
    row-gap: helpers.spacing(24) !important;
  }
  .gap-y-32 {
    row-gap: helpers.spacing(32) !important;
  }
  .gap-y-4 {
    row-gap: helpers.spacing(4) !important;
  }
  .gap-y-40 {
    row-gap: helpers.spacing(40) !important;
  }
  .gap-y-48 {
    row-gap: helpers.spacing(48) !important;
  }
  .gap-y-64 {
    row-gap: helpers.spacing(64) !important;
  }
  .gap-y-8 {
    row-gap: helpers.spacing(8) !important;
  }
  .overflow-auto {
    overflow: auto !important;
  }
  .overflow-hidden {
    overflow: hidden !important;
  }
  .overflow-clip {
    overflow: clip !important;
  }
  .overflow-visible {
    overflow: visible !important;
  }
  .overflow-scroll {
    overflow: scroll !important;
  }
  .overflow-x-auto {
    overflow-x: auto !important;
  }
  .overflow-y-auto {
    overflow-y: auto !important;
  }
  .overflow-x-hidden {
    overflow-x: hidden !important;
  }
  .overflow-y-hidden {
    overflow-y: hidden !important;
  }
  .overflow-x-clip {
    overflow-x: clip !important;
  }
  .overflow-y-clip {
    overflow-y: clip !important;
  }
  .overflow-x-visible {
    overflow-x: visible !important;
  }
  .overflow-y-visible {
    overflow-y: visible !important;
  }
  .overflow-x-scroll {
    overflow-x: scroll !important;
  }
  .overflow-y-scroll {
    overflow-y: scroll !important;
  }
  .truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }
  .overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
  .text-ellipsis {
    text-overflow: ellipsis !important;
  }
  .text-clip {
    text-overflow: clip !important;
  }
  .whitespace-normal {
    white-space: normal !important;
  }
  .whitespace-nowrap {
    white-space: nowrap !important;
  }
  .whitespace-pre {
    white-space: pre !important;
  }
  .whitespace-pre-line {
    white-space: pre-line !important;
  }
  .whitespace-pre-wrap {
    white-space: pre-wrap !important;
  }
  .whitespace-break-spaces {
    white-space: break-spaces !important;
  }
  .break-normal {
    overflow-wrap: normal !important;
    word-break: normal !important;
  }
  .break-words {
    overflow-wrap: break-word !important;
  }
  .break-all {
    word-break: break-all !important;
  }
  .break-keep {
    word-break: keep-all !important;
  }
  .p-0 {
    padding: helpers.spacing(0) !important;
  }
  .p-1 {
    padding: helpers.spacing(1) !important;
  }
  .p-12 {
    padding: helpers.spacing(12) !important;
  }
  .p-128 {
    padding: helpers.spacing(128) !important;
  }
  .p-16 {
    padding: helpers.spacing(16) !important;
  }
  .p-2 {
    padding: helpers.spacing(2) !important;
  }
  .p-24 {
    padding: helpers.spacing(24) !important;
  }
  .p-32 {
    padding: helpers.spacing(32) !important;
  }
  .p-4 {
    padding: helpers.spacing(4) !important;
  }
  .p-40 {
    padding: helpers.spacing(40) !important;
  }
  .p-48 {
    padding: helpers.spacing(48) !important;
  }
  .p-64 {
    padding: helpers.spacing(64) !important;
  }
  .p-8 {
    padding: helpers.spacing(8) !important;
  }
  .px-0 {
    padding-left: helpers.spacing(0) !important;
    padding-right: helpers.spacing(0) !important;
  }
  .px-1 {
    padding-left: helpers.spacing(1) !important;
    padding-right: helpers.spacing(1) !important;
  }
  .px-12 {
    padding-left: helpers.spacing(12) !important;
    padding-right: helpers.spacing(12) !important;
  }
  .px-128 {
    padding-left: helpers.spacing(128) !important;
    padding-right: helpers.spacing(128) !important;
  }
  .px-16 {
    padding-left: helpers.spacing(16) !important;
    padding-right: helpers.spacing(16) !important;
  }
  .px-2 {
    padding-left: helpers.spacing(2) !important;
    padding-right: helpers.spacing(2) !important;
  }
  .px-24 {
    padding-left: helpers.spacing(24) !important;
    padding-right: helpers.spacing(24) !important;
  }
  .px-32 {
    padding-left: helpers.spacing(32) !important;
    padding-right: helpers.spacing(32) !important;
  }
  .px-4 {
    padding-left: helpers.spacing(4) !important;
    padding-right: helpers.spacing(4) !important;
  }
  .px-40 {
    padding-left: helpers.spacing(40) !important;
    padding-right: helpers.spacing(40) !important;
  }
  .px-48 {
    padding-left: helpers.spacing(48) !important;
    padding-right: helpers.spacing(48) !important;
  }
  .px-64 {
    padding-left: helpers.spacing(64) !important;
    padding-right: helpers.spacing(64) !important;
  }
  .px-8 {
    padding-left: helpers.spacing(8) !important;
    padding-right: helpers.spacing(8) !important;
  }
  .py-0 {
    padding-top: helpers.spacing(0) !important;
    padding-bottom: helpers.spacing(0) !important;
  }
  .py-1 {
    padding-top: helpers.spacing(1) !important;
    padding-bottom: helpers.spacing(1) !important;
  }
  .py-12 {
    padding-top: helpers.spacing(12) !important;
    padding-bottom: helpers.spacing(12) !important;
  }
  .py-128 {
    padding-top: helpers.spacing(128) !important;
    padding-bottom: helpers.spacing(128) !important;
  }
  .py-16 {
    padding-top: helpers.spacing(16) !important;
    padding-bottom: helpers.spacing(16) !important;
  }
  .py-2 {
    padding-top: helpers.spacing(2) !important;
    padding-bottom: helpers.spacing(2) !important;
  }
  .py-24 {
    padding-top: helpers.spacing(24) !important;
    padding-bottom: helpers.spacing(24) !important;
  }
  .py-32 {
    padding-top: helpers.spacing(32) !important;
    padding-bottom: helpers.spacing(32) !important;
  }
  .py-4 {
    padding-top: helpers.spacing(4) !important;
    padding-bottom: helpers.spacing(4) !important;
  }
  .py-40 {
    padding-top: helpers.spacing(40) !important;
    padding-bottom: helpers.spacing(40) !important;
  }
  .py-48 {
    padding-top: helpers.spacing(48) !important;
    padding-bottom: helpers.spacing(48) !important;
  }
  .py-64 {
    padding-top: helpers.spacing(64) !important;
    padding-bottom: helpers.spacing(64) !important;
  }
  .py-8 {
    padding-top: helpers.spacing(8) !important;
    padding-bottom: helpers.spacing(8) !important;
  }
  .pb-0 {
    padding-bottom: helpers.spacing(0) !important;
  }
  .pb-1 {
    padding-bottom: helpers.spacing(1) !important;
  }
  .pb-12 {
    padding-bottom: helpers.spacing(12) !important;
  }
  .pb-128 {
    padding-bottom: helpers.spacing(128) !important;
  }
  .pb-16 {
    padding-bottom: helpers.spacing(16) !important;
  }
  .pb-2 {
    padding-bottom: helpers.spacing(2) !important;
  }
  .pb-24 {
    padding-bottom: helpers.spacing(24) !important;
  }
  .pb-32 {
    padding-bottom: helpers.spacing(32) !important;
  }
  .pb-4 {
    padding-bottom: helpers.spacing(4) !important;
  }
  .pb-40 {
    padding-bottom: helpers.spacing(40) !important;
  }
  .pb-48 {
    padding-bottom: helpers.spacing(48) !important;
  }
  .pb-64 {
    padding-bottom: helpers.spacing(64) !important;
  }
  .pb-8 {
    padding-bottom: helpers.spacing(8) !important;
  }
  .pe-0 {
    padding-inline-end: helpers.spacing(0) !important;
  }
  .pe-1 {
    padding-inline-end: helpers.spacing(1) !important;
  }
  .pe-12 {
    padding-inline-end: helpers.spacing(12) !important;
  }
  .pe-128 {
    padding-inline-end: helpers.spacing(128) !important;
  }
  .pe-16 {
    padding-inline-end: helpers.spacing(16) !important;
  }
  .pe-2 {
    padding-inline-end: helpers.spacing(2) !important;
  }
  .pe-24 {
    padding-inline-end: helpers.spacing(24) !important;
  }
  .pe-32 {
    padding-inline-end: helpers.spacing(32) !important;
  }
  .pe-4 {
    padding-inline-end: helpers.spacing(4) !important;
  }
  .pe-40 {
    padding-inline-end: helpers.spacing(40) !important;
  }
  .pe-48 {
    padding-inline-end: helpers.spacing(48) !important;
  }
  .pe-64 {
    padding-inline-end: helpers.spacing(64) !important;
  }
  .pe-8 {
    padding-inline-end: helpers.spacing(8) !important;
  }
  .pl-0 {
    padding-left: helpers.spacing(0) !important;
  }
  .pl-1 {
    padding-left: helpers.spacing(1) !important;
  }
  .pl-12 {
    padding-left: helpers.spacing(12) !important;
  }
  .pl-128 {
    padding-left: helpers.spacing(128) !important;
  }
  .pl-16 {
    padding-left: helpers.spacing(16) !important;
  }
  .pl-2 {
    padding-left: helpers.spacing(2) !important;
  }
  .pl-24 {
    padding-left: helpers.spacing(24) !important;
  }
  .pl-32 {
    padding-left: helpers.spacing(32) !important;
  }
  .pl-4 {
    padding-left: helpers.spacing(4) !important;
  }
  .pl-40 {
    padding-left: helpers.spacing(40) !important;
  }
  .pl-48 {
    padding-left: helpers.spacing(48) !important;
  }
  .pl-64 {
    padding-left: helpers.spacing(64) !important;
  }
  .pl-8 {
    padding-left: helpers.spacing(8) !important;
  }
  .pr-0 {
    padding-right: helpers.spacing(0) !important;
  }
  .pr-1 {
    padding-right: helpers.spacing(1) !important;
  }
  .pr-12 {
    padding-right: helpers.spacing(12) !important;
  }
  .pr-128 {
    padding-right: helpers.spacing(128) !important;
  }
  .pr-16 {
    padding-right: helpers.spacing(16) !important;
  }
  .pr-2 {
    padding-right: helpers.spacing(2) !important;
  }
  .pr-24 {
    padding-right: helpers.spacing(24) !important;
  }
  .pr-32 {
    padding-right: helpers.spacing(32) !important;
  }
  .pr-4 {
    padding-right: helpers.spacing(4) !important;
  }
  .pr-40 {
    padding-right: helpers.spacing(40) !important;
  }
  .pr-48 {
    padding-right: helpers.spacing(48) !important;
  }
  .pr-64 {
    padding-right: helpers.spacing(64) !important;
  }
  .pr-8 {
    padding-right: helpers.spacing(8) !important;
  }
  .pt-0 {
    padding-top: helpers.spacing(0) !important;
  }
  .pt-1 {
    padding-top: helpers.spacing(1) !important;
  }
  .pt-12 {
    padding-top: helpers.spacing(12) !important;
  }
  .pt-128 {
    padding-top: helpers.spacing(128) !important;
  }
  .pt-16 {
    padding-top: helpers.spacing(16) !important;
  }
  .pt-2 {
    padding-top: helpers.spacing(2) !important;
  }
  .pt-24 {
    padding-top: helpers.spacing(24) !important;
  }
  .pt-32 {
    padding-top: helpers.spacing(32) !important;
  }
  .pt-4 {
    padding-top: helpers.spacing(4) !important;
  }
  .pt-40 {
    padding-top: helpers.spacing(40) !important;
  }
  .pt-48 {
    padding-top: helpers.spacing(48) !important;
  }
  .pt-64 {
    padding-top: helpers.spacing(64) !important;
  }
  .pt-8 {
    padding-top: helpers.spacing(8) !important;
  }
  .text-left {
    text-align: left !important;
  }
  .text-center {
    text-align: center !important;
  }
  .text-right {
    text-align: right !important;
  }
  .text-justify {
    text-align: justify !important;
  }
  .text-start {
    text-align: start !important;
  }
  .text-end {
    text-align: end !important;
  }
  .uppercase {
    text-transform: uppercase !important;
  }
  .lowercase {
    text-transform: lowercase !important;
  }
  .capitalize {
    text-transform: capitalize !important;
  }
  .normal-case {
    text-transform: none !important;
  }
  .italic {
    font-style: italic !important;
  }
  .not-italic {
    font-style: normal !important;
  }
  .underline {
    text-decoration-line: underline !important;
  }
  .overline {
    text-decoration-line: overline !important;
  }
  .line-through {
    text-decoration-line: line-through !important;
  }
  .no-underline {
    text-decoration-line: none !important;
  }
  .opacity-0 {
    opacity: 0 !important;
  }
  .opacity-10 {
    opacity: 0.1 !important;
  }
  .opacity-100 {
    opacity: 1 !important;
  }
  .opacity-15 {
    opacity: 0.15 !important;
  }
  .opacity-20 {
    opacity: 0.2 !important;
  }
  .opacity-25 {
    opacity: 0.25 !important;
  }
  .opacity-30 {
    opacity: 0.3 !important;
  }
  .opacity-35 {
    opacity: 0.35 !important;
  }
  .opacity-40 {
    opacity: 0.4 !important;
  }
  .opacity-45 {
    opacity: 0.45 !important;
  }
  .opacity-5 {
    opacity: 0.05 !important;
  }
  .opacity-50 {
    opacity: 0.5 !important;
  }
  .opacity-55 {
    opacity: 0.55 !important;
  }
  .opacity-60 {
    opacity: 0.6 !important;
  }
  .opacity-65 {
    opacity: 0.65 !important;
  }
  .opacity-70 {
    opacity: 0.7 !important;
  }
  .opacity-75 {
    opacity: 0.75 !important;
  }
  .opacity-80 {
    opacity: 0.8 !important;
  }
  .opacity-85 {
    opacity: 0.85 !important;
  }
  .opacity-90 {
    opacity: 0.9 !important;
  }
  .opacity-95 {
    opacity: 0.95 !important;
  }
  .grow {
    flex: 1 1 0% !important;
  }
  .grow-1 {
    flex: 1 1 0% !important;
  }
  .grow-2 {
    flex: 2 1 0% !important;
  }
  .grow-3 {
    flex: 3 1 0% !important;
  }
  .grow-4 {
    flex: 4 1 0% !important;
  }
  .grow-5 {
    flex: 5 1 0% !important;
  }
  .grow-6 {
    flex: 6 1 0% !important;
  }
  .grow-7 {
    flex: 7 1 0% !important;
  }
  .grow-8 {
    flex: 8 1 0% !important;
  }
  .grow-9 {
    flex: 9 1 0% !important;
  }
  .row {
    display: flex;
    flex-direction: row;
    gap: helpers.spacing(8);
  }
  .row-group {
    display: flex;
    flex-direction: row;
  }
  .col {
    display: flex;
    flex-direction: column;
    min-width: 0;
    min-height: 0;
  }
  .font-bolder {
    font-weight: 600;
  }
  .font-bold {
    font-weight: 500;
  }
  .font-normal {
    font-weight: 400;
  }
  [hidden] {
    &#{&} {
      display: none !important;
    }
  }
  .align-sub {
    vertical-align: sub !important;
  }
}
