@use 'extends';
// [PURPOSE]: to rewrite some of existing CSS Angular Material styles
@use 'definitions';
@use 'sass:map' as map;

:root {
  --mdc-typography-button-letter-spacing: normal;
  --mat-paginator-container-text-color: #{definitions.$table-header-text-color};
}

.mat-form-field {
  font-size: map.get(definitions.$typography, input);
}

button.disable-ripple {
  // stylelint-disable-next-line selector-class-pattern
  .mdc-button__ripple, .mat-mdc-button-ripple {
    display: none;
  }
}

icz-button button {
  // stylelint-disable-next-line selector-class-pattern
  .mdc-button__ripple, .mat-mdc-button-ripple {
    border-radius: 0 !important;
  }
}

// stylelint-disable-next-line selector-class-pattern
.mdc-button__label {
  width: 100%;
}

// stylelint-disable-next-line selector-class-pattern
icz-button .mat-mdc-outlined-button .mdc-button__label {
  z-index: 0;
}

.tree-expansion-panel {
  .mat-expansion-panel-body {
    @extend %py-0, %px-8;
  }
}

.expansion-panel-white-arrow {
  .mat-expansion-indicator::after {
    @extend %inline-block, %p-4;
    border-style: solid;
    border-width: 0 3px 3px 0;
    content: '';
    border-color: definitions.$white;
    transform: rotate(45deg);
    vertical-align: middle;
  }
}

.mat-modal-icon-main {
  &#{&}#{&} {
    width: 100px;
  }
}

.icz-text-muted {
  color: definitions.$black700;
}

.mat-menu-panel {
  // To beat Material styles
  // Fix for unnecesary height of menu, when there is 1 item only
  min-height: 34px !important;
  max-width: 300px !important;

  icz-button {
    .label {
      @extend %text-left;
    }
  }
}

.mat-tree-node.mat-tree-node {
  @extend %min-h-40;
}

.mat-checkbox-layout .mat-checkbox-label {
  @extend %font-normal;
  font-size: 14px;
}

.icz-body-1 {
  font-size: definitions.$body-base-font-size;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}

.icz-error {
  color: definitions.$red900;
}

.icz-warning {
  color: definitions.$orange900;
}

.input-label {
  --label-height: calc(#{definitions.$input-min-height} / 2);

  @extend %inline-block, %cursor-default, %truncate, %px-1;
  color: definitions.$black700;
  height: calc(var(--label-height) + 1px);
  line-height: var(--label-height);
  margin-bottom: calc(-1 * (var(--label-height) / 3.5));
  font-size: definitions.$body-base-font-size;

  &.focused {
    color: definitions.$black900;
  }
}

.icz-select-placeholder {
  color: definitions.$black600;
  font-size: definitions.$body-base-font-size;
}

icz-table-paginator, icz-paginator {
  mat-paginator {
    // style correction for text in mat-paginator
    .mat-mdc-paginator-range-label {
      margin: 0;
    }

    // hides material buttons of mat-paginator
    .mdc-icon-button {
      display: none;
    }
  }
}

.mat-content.mat-content-hide-toggle {
  @extend %mr-0;
}
